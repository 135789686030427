import { createReducer } from '@reduxjs/toolkit'

import {
  resetInterviewsStateAction,
  resetEntityStateAction,
  setInterviewsStateAction,
  getAllInterviewSlotsAction,
  getSingleInterviewSlotAction,
  putSingleInterviewSlotAction,
  postSingleInterviewSlotAction,
  deleteInterviewSlotAction,
} from './actions'

const initialState = {
  loading: false,
  entity: {},
  entities: [],
  meta: {},
  errors: {},
}

const interviewSlotsReducer = createReducer(initialState, (builder) => {
  builder
    // RESET STATE
    .addCase(resetInterviewsStateAction, (state) => {
      Object.assign(state, initialState)
    })
    // RESET ENTITY STATE
    .addCase(resetEntityStateAction, (state) => {
      state.entity = {}
    })
    // SET INTERVIEWS STATE
    .addCase(setInterviewsStateAction, (state, action) => {
      state.entities = action.payload
    })
    // GET ALL INTERVIEW SLOTS
    .addCase(getAllInterviewSlotsAction.pending, (state) => {
      state.loading = true
    })
    .addCase(getAllInterviewSlotsAction.rejected, (state, action) => {
      state.loading = false
      state.errors = action.error
    })
    .addCase(getAllInterviewSlotsAction.fulfilled, (state, action) => {
      state.entities = action?.payload?.data
      state.meta = action?.payload?.meta
      state.loading = false
    })
    // GET SINGLE INTERVIEW SLOT
    .addCase(getSingleInterviewSlotAction.pending, (state) => {
      state.loading = true
    })
    .addCase(getSingleInterviewSlotAction.rejected, (state, action) => {
      state.loading = false
      state.errors = action.error
    })
    .addCase(getSingleInterviewSlotAction.fulfilled, (state, action) => {
      state.entity = action?.payload?.data
      state.loading = false
    })
    // PUT SINGLE INTERVIEW SLOT
    .addCase(putSingleInterviewSlotAction.pending, (state) => {
      state.loading = true
    })
    .addCase(putSingleInterviewSlotAction.fulfilled, (state, action) => {
      state.loading = false
    })
    .addCase(putSingleInterviewSlotAction.rejected, (state, action) => {
      state.loading = false
      state.errors = action.error
    })
    // CREATE SINGLE INTERVIEW SLOT
    .addCase(postSingleInterviewSlotAction.pending, (state) => {
      state.loading = true
    })
    .addCase(postSingleInterviewSlotAction.fulfilled, (state) => {
      state.loading = false
    })
    .addCase(postSingleInterviewSlotAction.rejected, (state, action) => {
      state.loading = false
      state.errors = action.error
    })
    // DELETE SINGLE INTERVIEW SLOT
    .addCase(deleteInterviewSlotAction.pending, (state) => {
      state.loading = true
    })
    .addCase(deleteInterviewSlotAction.rejected, (state, action) => {
      state.loading = false
      state.errors = action.payload
    })
    .addCase(deleteInterviewSlotAction.fulfilled, (state, action) => {
      state.loading = false
      state.entities = action?.payload
    })
})

export default interviewSlotsReducer
