import { getApi, patchApi } from './request'

export const getSectorList = (params) => {
  const filter = typeof params === 'object' ? params.filter : undefined
  return getApi(`/sectors`, { filter })
}

export const getSectorsCount = async () => {
  try {
    const { data } = await getApi('/sectors/count')
    return data
  } catch (error) {
    console.log("sector service error::count")
    throw error
  }
}

export const updateSingleSector = async (params) => {
  try {
    await patchApi(`/sectors/${params.id}`, params.data)
  } catch (error) {
    throw error
  }
}
