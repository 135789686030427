import { createAsyncThunk } from '@reduxjs/toolkit'

import { getSectorList, getSectorsCount, updateSingleSector } from 'src/services/sectorsSvc'
import { GET_ALL_SECTORS, UPDATE_SINGLE_SECTOR } from './constants'

export const getAllSectorsAct = createAsyncThunk(
  GET_ALL_SECTORS,
  async (params) => {
    try {
      const { data } = await getSectorList(params)
      const meta = await getSectorsCount()
      return {
        data,
        meta,
      }
    } catch (error) {
      return Promise.reject(error?.data?.error)
    }
  }
)

export const updateSingleSectorAct = createAsyncThunk(
  UPDATE_SINGLE_SECTOR,
  async (payload) => {
    try {
      await updateSingleSector(payload)
    } catch (error) {
      return Promise.reject(error?.data?.error)
    }
  }
)
