import { lazy } from 'react';

const CandidateMedicalTest = lazy(() => import('../components/CandidateMedicalTest/CandidateMedicalTest'))

const adminRoutes = [
  {
    name: 'medicalTest',
    path: '/medical-test',
    handler: CandidateMedicalTest,
    role: ['admin', 'medical'],
  },
];

export default adminRoutes;
