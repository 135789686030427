import { createReducer } from '@reduxjs/toolkit'

import { getAllSectorsAct, updateSingleSectorAct } from './actions' 

const initialState = {
  loading: false,
  entity: {},
  entities: [],
  meta: {},
  errors: {},
}

const sectorsReducer = createReducer(initialState, (builder) => {
  builder
    // GET ALL SECTORS
    .addCase(getAllSectorsAct.pending, (state) => {
      state.loading = true
    })
    .addCase(getAllSectorsAct.fulfilled, (state, action) => {
      state.entities = action.payload.data
      state.meta = action.payload.meta
      state.loading = false
    })
    .addCase(getAllSectorsAct.rejected, (state, action) => {
      state.errors = action.error
      state.loading = false
    })
    // UPDATE SINGLE SECTOR
    .addCase(updateSingleSectorAct.pending, (state) => {
      state.loading = true
    })
    .addCase(updateSingleSectorAct.fulfilled, (state, action) => {
      state.loading = false
    })
    .addCase(updateSingleSectorAct.rejected, (state, action) => {
      state.loading = false
      state.errors = action.error
    })
})

export default sectorsReducer
