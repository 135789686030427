import { useCallback, useEffect, useState } from 'react'
import { isEqual } from 'lodash'
import Axios from 'axios'

import { Storage } from 'src/services/storage'
import { postLogin, getLogout, fetchUserData } from 'src/utils/httpClient'
import { getUserAcls } from 'src/utils/authUtils'
import acls from 'src/constants/acl'

const UseAuth = () => {
  const [userProfile, setUserProfile] = useState({})
  const [loading, setLoading] = useState(false)
  const [userAcls, setUserAcls] = useState([])
  const API_BASE_URL = useState(process.env.REACT_APP_API_BASE_URL)[0]

  const logout = () => getLogout()

  const loadUserProfile = useCallback(async () => {
    const now = Math.round(Date.now() / 1000)
    setLoading(true)
    try {
      const simulating = await Storage.getItem('simulate-user')
      const userProfile = await Storage.getItem(
        simulating ? 'iuserProfile' : 'userProfile',
      )
      if (userProfile && Object.keys(userProfile).length > 0) {
        if (now > userProfile.exp) {
          if (simulating) Storage.removeItem('simulate-user')
          getLogout()
        }
        setUserProfile(userProfile)
        setUserAcls(getUserAcls(acls, userProfile.roles))
      } else {
        logout()
      }
    } catch (error) {
      console.warn(error)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    loadUserProfile()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const stopSimulating = () => {
    Storage.removeItem('simulate-user')
    Storage.removeItem('iuserProfile')
    Storage.removeItem('itoken')
    Storage.removeItem('irefresh')
    window.location.replace('/')
  }

  const login = async (payload) => {
    try {
      const loginResp = await postLogin(payload)
      return loginResp
    } catch (error) {
      throw error
    }
  }

  const refetchUserData = async () => {
    try {
      const existingUserProfile = Storage.getItemSync('userProfile')
      const role = Array.isArray(existingUserProfile?.roles)
        ? existingUserProfile?.roles
        : []

      if (role.includes('candidate')) return

      const { data } = await fetchUserData()
      if (isEqual(existingUserProfile, data)) return data
      Storage.setItem('userProfile', data)
      const refreshToken = Storage.getItemSync('refresh')
      const { data: tokenData } = await Axios.post(
        `${API_BASE_URL}/users/refresh`,
        {
          refreshToken,
        },
      )
      if (tokenData?.token) {
        Storage.setItem('token', tokenData.token)
      }
      window.location.replace('/')
      // window.location.reload()
    } catch (error) {}
  }

  return {
    loading,
    login,
    isLoggedIn: !!Object.keys(userProfile).length,
    isAdmin: userProfile?.roles?.includes('admin') || false,
    isManager: userProfile?.roles?.includes('manager') || false,
    isCommittee: userProfile?.roles?.includes('committee') || false,
    isOperations: userProfile?.roles?.includes('operations') || false,
    userProfile,
    userAcls,
    logout,
    stopSimulating,
    refetchUserData,
  }
}

export default UseAuth
