import React from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import './Loader.css'

const Wrapper = styled.div`
  .modal-content {
    border: unset !important;
    background: transparent !important;
  }
`

const OverlayLoader = ({ show }) => (
  <Wrapper>
    <Modal show={show} fullscreen="true" onHide={() => { }} className='border-modal' centered>
      <Spinner className="loading" variant="primary" animation="grow" />
    </Modal>
  </Wrapper>
)

OverlayLoader.propTypes = {
  show: PropTypes.bool,
}

OverlayLoader.defaultProps = {
  show: false,
}

export default OverlayLoader
