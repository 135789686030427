const prefix = 'interviews/'

export const RESET_INTERVIEWS_SLOTS_STATE = `${prefix}reset`
export const RESET_ENTITY_STATE = `${prefix}reset-entity`
export const SET_INTERVIEWS_SLOTS_STATE = `${prefix}set`
export const GET_ALL_INTERIEW_SLOTS = `${prefix}get-all-interview-slots`
export const GET_SINGLE_INTERIEW_SLOT = `${prefix}get-single-interview-slot`
export const PATCH_INTERIEW_SLOT = `${prefix}patch`
export const PUT_INTERIEW_SLOT = `${prefix}put`
export const POST_INTERIEW_SLOT = `${prefix}post`
export const DELETE_INTERIEW_SLOT = `${prefix}delete`
