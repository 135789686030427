import { createReducer } from '@reduxjs/toolkit'

import {
  resetJobStatsStateAction,
  resetEntityStateAction,
  setJobStatsStateAction,
  getAllJobStatsAction,
  getSinglejobStatsAction,
} from './actions'

const initialState = {
  loading: false,
  entity: {},
  entities: [], // now we're working with just the jobs data, not the stats
  // when this is updated to use stats, the entities should be renamed and
  // we'll use an object instead of an array
  meta: {},
  errors: {},
}

const jobStatsReducer = createReducer(initialState, (builder) => {
  builder
    // RESET STATE
    .addCase(resetJobStatsStateAction, (state) => {
      Object.assign(state, initialState)
    })
    // RESET ENTITY STATE
    .addCase(resetEntityStateAction, (state) => {
      state.entity = {}
    })
    // SET JOB STATS STATE
    .addCase(setJobStatsStateAction, (state, action) => {
      state.entities = action.payload
    })
    // GET ALL JOBS STATS
    .addCase(getAllJobStatsAction.pending, (state) => {
      state.loading = true
    })
    .addCase(getAllJobStatsAction.rejected, (state, action) => {
      state.loading = false
      state.errors = action.error
    })
    .addCase(getAllJobStatsAction.fulfilled, (state, action) => {
      state.entities = action?.payload?.data
      state.meta = action?.payload?.meta
      state.loading = false
    })
    // GET SINGLE JOB STATS
    .addCase(getSinglejobStatsAction.pending, (state) => {
      state.loading = true
    })
    .addCase(getSinglejobStatsAction.rejected, (state, action) => {
      state.loading = false
      state.errors = action.error
    })
    .addCase(getSinglejobStatsAction.fulfilled, (state, action) => {
      state.entity = action?.payload?.data
      state.loading = false
    })
})

export default jobStatsReducer
