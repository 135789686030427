import { lazy } from 'react';

const MainDashboard = lazy(() =>
  import('../components/Applicants/MainDashboard/MainDashboard'),
)
const JobDescription = lazy(() =>
  import('../components/Applicants/JobDescription/JobDescription'),
)
const JobOffer = lazy(
  () => import('../components/Applicants/JobOffer/JobOffer')
)
const ApplicantData = lazy(() =>
  import('../components/Applicants/ApplicantData/ApplicantData'),
)
const ApplicantDocs = lazy(() =>
  import('../components/Applicants/ApplicantDocs/ApplicantDocs'),
)
const ApplicantRegion = lazy(() =>
  import('../components/Applicants/ApplicantRegion/ApplicantRegion'),
)
const ApplicantCity = lazy(() =>
  import('../components/Applicants/ApplicantCity/ApplicantCity'),
)
const ApplicantInterview = lazy(() =>
  import('../components/Applicants/ApplicantInterview/ApplicantInterview'),
)
const ApplicantSchool = lazy(() =>
  import('../components/Applicants/ApplicantSchool/ApplicantSchool'),
)
const ApplicantContractText = lazy(() =>
  import('../components/Applicants/ApplicantContractText/ApplicantContractText'),
)

const candidateRoutes = [
  {
    name: 'main',
    path: '/main',
    handler: MainDashboard,
    role: ['candidate'],
  },
  {
    name: 'jobDescription',
    path: '/job-description',
    handler: JobDescription,
    role: ['candidate'],
  },
  {
    name: 'jobOffer',
    path: '/job-offer',
    handler: JobOffer,
    role: ['candidate'],
  },
  {
    name: 'applicantData',
    path: '/applicant-data',
    handler: ApplicantData,
    role: ['candidate'],
  },
  {
    name: 'applicantDocs',
    path: '/applicant-documents',
    handler: ApplicantDocs,
    role: ['candidate'],
  },
  {
    name: 'applicantRegion',
    path: '/applicant-region',
    handler: ApplicantRegion,
    role: ['candidate'],
  },
  {
    name: 'applicantCity',
    path: '/applicant-city',
    handler: ApplicantCity,
    role: ['candidate'],
  },
  {
    name: 'applicantInterview',
    path: '/applicant-interview',
    handler: ApplicantInterview,
    role: ['candidate'],
  },
  {
    name: 'applicantSchool',
    path: '/applicant-school',
    handler: ApplicantSchool,
    role: ['candidate'],
  },
  {
    name: 'applicantContract',
    path: '/applicant-contract',
    handler: ApplicantContractText,
    role: ['candidate'],
  },
];

export default candidateRoutes;
