import utf8 from 'utf8'
import { deleteApi, getApi, patchApi, postApi, putApi } from './request'

export const postInterviewSlot = (payload) => {
  return postApi('/interview-time-slots', payload)
}

export const bulkCreateInterviewSlot = (selectedFile) => {
  const formData = new FormData()
  formData.append('file', selectedFile)
  return postApi('/interview-time-slots/bulk-upload', formData, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  })
}

export const putInterviewSlot = (payload) => {
  return putApi(`/interview-time-slots/${payload?.id}`, payload)
}

export const patchInterviewSlot = (payload) => {
  return patchApi(`/interview-time-slots/${payload?.id}`, payload)
}

export const getSingleInterviewSlot = (InterviewSlotId) => {
  return getApi(`/interview-time-slots/${InterviewSlotId}`)
}

export const deleteInterviewSlot = (id) => {
  return deleteApi(`/interview-time-slots/${id}`)
}

export const getAllInterviewSlots = async (params) => {
  try {
    const { pagination = {}, order = {}, where = {} } = params

    const ordersBy = Object.keys(order).map((it) => `${it} ${order[it]}`)

    const whereIs = {}
    Object.keys(where).map((it) => {
      if (it === 'locationName') {
        whereIs[it] = {
          like: `%${utf8.encode(where[it])}%`,
        }
      }
      if (it === 'id') {
        whereIs[it] = {
          eq: where[it],
        }
      }
      if (it === 'jobPositionId') {
        whereIs[it] = {
          eq: where[it],
        }
      }
      if (it === 'administrationId') {
        whereIs[it] = {
          eq: where[it],
        }
      }
    })

    const paginationsIs = {
      limit: 10,
      skip: 0,
      ...pagination,
    }

    const jsonQueries = escape(
      JSON.stringify({
        ...paginationsIs,
        ...(ordersBy.length > 0 && {
          order: ordersBy,
        }),
        ...(Object.keys(whereIs).length > 0 && {
          where: whereIs,
        }),
      }),
    )

    const whereQuery = escape(JSON.stringify(whereIs))

    const [count, items] = await Promise.allSettled([
      getApi(`/interview-time-slots/count?where=${whereQuery}`),
      getApi(`/interview-time-slots?filter=${jsonQueries}`),
    ])

    return {
      data: items?.value?.data,
      meta: count?.value?.data,
    }
  } catch (error) {
    throw error
  }
}
