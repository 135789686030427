import { createAction, createAsyncThunk, isRejectedWithValue } from '@reduxjs/toolkit'

import {
  postInterviewSlot,
  putInterviewSlot,
  getSingleInterviewSlot,
  deleteInterviewSlot,
  getAllInterviewSlots,
} from '../../services/interviewsSvc'
import {
  RESET_INTERVIEWS_SLOTS_STATE,
  RESET_ENTITY_STATE,
  SET_INTERVIEWS_SLOTS_STATE,
  GET_ALL_INTERIEW_SLOTS,
  GET_SINGLE_INTERIEW_SLOT,
  PUT_INTERIEW_SLOT,
  POST_INTERIEW_SLOT,
  DELETE_INTERIEW_SLOT,
} from './constants'

export const resetInterviewsStateAction = createAction(RESET_INTERVIEWS_SLOTS_STATE)

export const resetEntityStateAction = createAction(RESET_ENTITY_STATE)

export const setInterviewsStateAction = createAction(SET_INTERVIEWS_SLOTS_STATE)

export const getAllInterviewSlotsAction = createAsyncThunk(
  GET_ALL_INTERIEW_SLOTS,
  async (params) => {
    try {
      const resp = await getAllInterviewSlots(params)
      return resp
    } catch (error) {
      return Promise.reject(error?.data?.error)
    }
  },
)

export const getSingleInterviewSlotAction = createAsyncThunk(
  GET_SINGLE_INTERIEW_SLOT,
  async (id) => {
    try {
      const resp = await getSingleInterviewSlot(id)
      return resp
    } catch (error) {
      return Promise.reject(error?.data?.error)
    }
  }
)

export const putSingleInterviewSlotAction = createAsyncThunk(
  PUT_INTERIEW_SLOT,
  async (payload) => {
    try {
      const resp = await putInterviewSlot(payload)
      return resp.data
    } catch (error) {
      return Promise.reject(error?.data?.error)
    }
  }
)

export const postSingleInterviewSlotAction = createAsyncThunk(
  POST_INTERIEW_SLOT,
  async (payload) => {
    try {
      const resp = await postInterviewSlot(payload)
      return resp.data
    } catch (error) {
      return Promise.reject(error?.data?.error)
    }
  }
)

export const deleteInterviewSlotAction = createAsyncThunk(
  DELETE_INTERIEW_SLOT,
  async ({ id, filter }, { fulfillWithValue }) => {
    try {
      await deleteInterviewSlot(id)
      const resp = await getAllInterviewSlots(filter)
      return fulfillWithValue(resp.data)
    } catch (error) {
      return isRejectedWithValue(error?.data)
    }
  }
)
