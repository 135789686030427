import { lazy } from 'react';

import adminRoutes from './admin-routes';
import candidateRoutes from './candidate-routes';
import medicalRoutes from './medical-routes';
import authUtil from 'src/utils/authUtils'

const UnAuthorized = lazy(() => import('../views/UnAuthorized/UnAuthorized'))

const routes  = [
  ...adminRoutes,
  ...candidateRoutes,
  ...medicalRoutes,
];

export const createRoutes = (user) =>
  routes.map((route) => ({
    name: route?.name,
    path: route?.path,
    handler:
      route?.role && !authUtil.hasRole(user, route?.role)
        ? UnAuthorized
        : route?.handler,
  }))
