import utf8 from 'utf8'
import { getApi } from './request'
import { saveAs } from 'file-saver'

export const getSingleJobStats = (jobPositionId) => {
  return getApi(`/job-positions/${jobPositionId}`)
}

export const getAllJobStats = async (params) => {
  try {
    const { pagination = {}, order = {}, where = {} } = params

    const ordersBy = Object.keys(order).map((it) => `${it} ${order[it]}`)

    const whereIs = {}
    Object.keys(where).map((it) => {
      whereIs[it] = {
        like: `%${utf8.encode(where[it])}%`,
      }
    })

    const paginationsIs = {
      limit: 10,
      skip: 0,
      ...pagination,
    }

    const jsonQueries = escape(
      JSON.stringify({
        ...paginationsIs,
        ...(ordersBy.length > 0 && {
          order: ordersBy,
        }),
        ...(Object.keys(whereIs).length > 0 && {
          where: whereIs,
        }),
      }),
    )
    const whereQuery = escape(JSON.stringify(whereIs))

    const [count, items] = await Promise.allSettled([
      getApi(`/job-positions/count?where=${whereQuery}`),
      getApi(`/job-positions?filter=${jsonQueries}`),
    ])

    return {
      data: items?.value?.data,
      meta: count?.value?.data,
    }
  } catch (error) {
    throw error
  }
}

export const downloadJobStatsExcel = async (filename) => {
  const response = await getApi('/candidates/exportStats', null, {
    responseType: 'blob',
  })
  return saveAs(response.data, filename)
}
