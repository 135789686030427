import { createReducer } from '@reduxjs/toolkit'

import {
  getUserListAct,
  getSingleUserAct,
  postUserAct,
  putUserAct,
  resetUserAct,
  deleteSingleUserAct,
} from './actions'

const initialState = {
  loading: false,
  entity: {},
  entities: [],
  meta: {},
  errors: {},
}

const userReducer = createReducer(initialState, (builder) => {
  builder
    // RESET USER ENTITY
    .addCase(resetUserAct, (state) => {
      state.entity = {}
    })
    // GET user list
    .addCase(getUserListAct.pending, (state) => {
      state.loading = true
    })
    .addCase(getUserListAct.fulfilled, (state, actions) => {
      state.loading = false
      state.entities = actions?.payload?.data
      state.meta = actions?.payload?.meta
    })
    .addCase(getUserListAct.rejected, (state, action) => {
      state.loading = false
      state.errors = action.error
    })
    // GET SINGLE USER
    .addCase(getSingleUserAct.pending, (state) => {
      state.loading = true
    })
    .addCase(getSingleUserAct.fulfilled, (state, action) => {
      state.loading = false
      state.entity = action.payload
    })
    .addCase(getSingleUserAct.rejected, (state, action) => {
      state.loading = false
      state.errors = action.error
    })
    // POST user
    .addCase(postUserAct.pending, (state) => {
      state.loading = true
    })
    .addCase(postUserAct.fulfilled, (state, actions) => {
      state.loading = false
      state.entity = actions.payload
    })
    .addCase(postUserAct.rejected, (state, action) => {
      state.loading = false
      state.errors = action.error
    })
    // PUT USER
    .addCase(putUserAct.pending, (state) => {
      state.loading = true
    })
    .addCase(putUserAct.fulfilled, (state, action) => {
      state.loading = false
      state.entity = action.payload
    })
    .addCase(putUserAct.rejected, (state, action) => {
      state.loading = false
      state.errors = action.error
    })
    // DELETE USER
    .addCase(deleteSingleUserAct.pending, (state) => {
      state.loading = true
    })
    .addCase(deleteSingleUserAct.fulfilled, (state, action) => {
      state.loading = false
      state.entities = action?.payload
    })
    .addCase(deleteSingleUserAct.rejected, (state, action) => {
      state.loading = false
      state.errors = action.error
    })
})

export default userReducer
