import { createAction, createAsyncThunk } from '@reduxjs/toolkit'

import {
  getAllJobStats,
  getSingleJobStats,
} from '../../services/jobStatsSvc'

import {
  RESET_JOB_STATS_STATE,
  RESET_ENTITY_STATE,
  SET_JOB_STATS_STATE,
  GET_ALL_JOB_STATS,
  GET_SINGLE_JOB_STAT,
} from './constants'

export const resetJobStatsStateAction = createAction(RESET_JOB_STATS_STATE)

export const resetEntityStateAction = createAction(RESET_ENTITY_STATE)

export const setJobStatsStateAction = createAction(SET_JOB_STATS_STATE)

export const getAllJobStatsAction = createAsyncThunk(
  GET_ALL_JOB_STATS,
  async (params) => {
    try {
      const resp = await getAllJobStats(params)
      return resp
    } catch (error) {
      return Promise.reject(error?.data?.error)
    }
  },
)

export const getSinglejobStatsAction = createAsyncThunk(
  GET_SINGLE_JOB_STAT,
  async (id) => {
    try {
      const resp = await getSingleJobStats(id)
      return resp
    } catch (error) {
      return Promise.reject(error?.data?.error)
    }
  }
)
