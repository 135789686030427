import { createAction, createAsyncThunk } from '@reduxjs/toolkit'

import {
  getUserList,
  getSingleUser,
  postUser,
  putUser,
  deleteSingleUser,
  signInAsUser,
} from '../../services/usersSvc'

import {
  GET_USER_LIST,
  GET_SINGLE_USER,
  POST_USER,
  PUT_USER,
  RESET_USER_ENTITY,
  DELETE_USER,
  SIGN_IN_AS,
} from './constants'

export const signInAsAct = createAsyncThunk(SIGN_IN_AS, async (params) => {
  try {
    const resp = await signInAsUser(params)
    return resp
  } catch (error) {
    return Promise.reject(error?.data?.error)
  }
})

export const resetUserAct = createAction(RESET_USER_ENTITY)

export const getUserListAct = createAsyncThunk(
  GET_USER_LIST,
  async (params) => {
    try {
      const resp = await getUserList(params)
      return resp
    } catch (error) {
      return Promise.reject(error?.data?.error)
    }
  },
)

export const getSingleUserAct = createAsyncThunk(
  GET_SINGLE_USER,
  async (userId) => {
    try {
      const resp = await getSingleUser(userId)
      return resp
    } catch (error) {
      return Promise.reject(error?.data?.error)
    }
  },
)

export const postUserAct = createAsyncThunk(POST_USER, async (payload) => {
  try {
    const resp = await postUser(payload)
    return resp.data
  } catch (error) {
    return Promise.reject(error?.data?.error)
  }
})

export const putUserAct = createAsyncThunk(PUT_USER, async (payload) => {
  try {
    const resp = await putUser(payload)
    return resp.data
  } catch (error) {
    return Promise.reject(error?.data?.error)
  }
})

export const deleteSingleUserAct = createAsyncThunk(
  DELETE_USER,
  async ({ id, filter }) => {
    try {
      await deleteSingleUser(id)
      const resp = await getUserList(filter)
      return resp.data
    } catch (error) {
      return Promise.reject(error?.data?.error)
    }
  },
)
