import { lazy } from 'react'

const AdminDashboard = lazy(() =>
  import('../components/Admin/MainDashboard/AdminDashboard'),
)
const QualificationStages = lazy(() =>
  import('../components/Admin/QualificationStages/QualificationStages'),
)
const ManageApplicants = lazy(() =>
  import('../components/Admin/ManageApplicants/ManageApplicants'),
)
const CandidateCreate = lazy(() =>
  import('../components/Admin/ManageApplicants/CandidateCreate'),
)
const CandidateEdit = lazy(() =>
  import('../components/Admin/ManageApplicants/CandidateEdit'),
)
const ContractOnboarding = lazy(() =>
  import('../components/Admin/ManageApplicants/ContractOnboardingStage'),
)
const JobPositionCreate = lazy(() =>
  import('../components/Admin/ManageJobPositions/JobPositionCreate'),
)
const JobPositionEdit = lazy(() =>
  import('../components/Admin/ManageJobPositions/JobPositionEdit'),
)
const BulkCreateCandidate = lazy(() =>
  import('../components/Admin/BulkCreateCandidate/BulkCreateCandidate'),
)
const BulkUpdateCandidate = lazy(() =>
  import('../components/Admin/BulkUpdateCandidate/BulkUpdateCandidate'),
)
const JobPositions = lazy(() =>
  import('../components/Admin/ManageJobPositions/JobPositionsList'),
)
const UserList = lazy(() =>
  import('../components/Admin/UserManagement/UserList'),
)
const UserCreate = lazy(() =>
  import('../components/Admin/UserManagement/UserCreate'),
)
const UserEdit = lazy(() =>
  import('../components/Admin/UserManagement/UserEdit'),
)
const SettingsList = lazy(() =>
  import('../components/Admin/Settings/SettingsList'),
)
const SettingCreate = lazy(() =>
  import('../components/Admin/Settings/SettingCreate'),
)
const SettingEdit = lazy(() =>
  import('../components/Admin/Settings/SettingEdit'),
)
const ManageInterviews = lazy(() =>
  import('../components/Admin/ManageInterviews/ManageInterviews'),
)
const CreateInterviewsSlots = lazy(() =>
  import('../components/Admin/ManageInterviews/InterviewSlotCreate'),
)
const BulkCreateInterview = lazy(() =>
  import('../components/Admin/ManageInterviews/InterviewBulkCreate'),
)
const EditInterviewSlots = lazy(() =>
  import('../components/Admin/ManageInterviews/InterviewSlotEdit'),
)
const SingleJobStats = lazy(() =>
  import('../components/Admin/JobStatistics/SinglejobStats'),
)
const ManageVacancies = lazy(() =>
  import('../components/Admin/ManageVacancies/ManageVacancies'),
)

const BulkActionsCandidate = lazy(() =>
  import('../components/Admin/BulkActionCandidate/BulkActionCandidate'),
)

const CandidateReports = lazy(() =>
  import('../components/Admin/CandidateReports/CandidateReports'),
)

const Setup2FA = lazy(() => import('../components/Admin/Setup2FA/Setup2FA'))

const adminRoutes = [
  {
    name: 'mainAdmin',
    path: '/admin',
    handler: AdminDashboard,
    role: ['admin', 'manager'],
  },
  {
    name: 'manageApplicant',
    path: '/admin/candidates',
    handler: ManageApplicants,
    role: ['admin', 'operations', 'committee', 'manager'],
  },
  {
    name: 'candidateCreate',
    path: '/admin/candidates/create',
    handler: CandidateCreate,
    role: ['admin'],
  },
  {
    name: 'adminCandidateBulkCreate',
    path: '/admin/candidates/bulk-create',
    handler: BulkCreateCandidate,
    role: ['admin'],
  },
  {
    name: 'adminCandidateBulkUpdate',
    path: '/admin/candidates/bulk-update',
    handler: BulkUpdateCandidate,
    role: ['admin'],
  },
  {
    name: 'adminCandidateBulkActions',
    path: '/admin/candidates/bulk-actions',
    handler: BulkActionsCandidate,
    role: ['admin'],
  },
  {
    name: 'adminCandidateReports',
    path: '/admin/candidates/reports',
    handler: CandidateReports,
    role: ['admin', 'operations'],
  },
  {
    name: 'candidateEdit',
    path: '/admin/candidates/:candidateId',
    handler: CandidateEdit,
    role: ['admin', 'operations', 'committee', 'manager'],
  },
  {
    name: 'qualificationStages',
    path: '/admin/qualification-stages',
    handler: QualificationStages,
    role: ['admin'],
  },
  {
    name: 'contractOnboarding',
    path: '/admin/contract-onboarding',
    handler: ContractOnboarding,
    role: ['admin'],
  },
  {
    name: 'adminJobPositionCreate',
    path: '/admin/job-positions/create',
    handler: JobPositionCreate,
    role: ['admin'],
  },
  {
    name: 'adminJobPositionEdit',
    path: '/admin/job-positions/:jobPositionId',
    handler: JobPositionEdit,
    role: ['admin'],
  },
  {
    name: 'adminJobPositionList',
    path: '/admin/job-positions',
    handler: JobPositions,
    role: ['admin', 'manager'],
  },
  {
    name: 'usersList',
    path: '/admin/users',
    handler: UserList,
    role: ['admin'],
  },
  {
    name: 'usersCreate',
    path: '/admin/users/create',
    handler: UserCreate,
    role: ['admin'],
  },
  {
    name: 'usersEdit',
    path: '/admin/users/:userId',
    handler: UserEdit,
    role: ['admin'],
  },
  {
    name: 'settingsList',
    path: '/admin/settings',
    handler: SettingsList,
    role: ['admin'],
  },
  {
    name: 'settingCreate',
    path: '/admin/settings/create',
    handler: SettingCreate,
    role: ['admin'],
  },
  {
    name: 'settingEdit',
    path: '/admin/settings/:settingId',
    handler: SettingEdit,
    role: ['admin'],
  },
  {
    name: 'manageInterviews',
    path: '/admin/interview-management',
    handler: ManageInterviews,
    role: ['admin'],
  },
  {
    name: 'interviewCreate',
    path: '/admin/interview-management/create',
    handler: CreateInterviewsSlots,
    role: ['admin'],
  },
  {
    name: 'interviewBulkCreate',
    path: '/admin/interview-management/bulk-create',
    handler: BulkCreateInterview,
    role: ['admin', 'operations'],
  },
  {
    name: 'interviewEdit',
    path: '/admin/interview-management/:interviewSlotId',
    handler: EditInterviewSlots,
    role: ['admin'],
  },
  {
    name: 'singleJobStats',
    path: '/admin/job-stats/:jobId',
    handler: SingleJobStats,
    role: ['admin', 'manager'],
  },
  {
    name: 'manageVacancies',
    path: '/admin/vacancies',
    handler: ManageVacancies,
    role: ['admin'],
  },
  {
    name: 'setup2FA',
    path: '/admin/setup-2fa',
    handler: Setup2FA,
    role: ['admin'],
  },
]

export default adminRoutes
