import React, { useMemo, lazy, useEffect, useState } from 'react'
import { Switch, Route } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { ErrorBoundary } from '@sentry/react'

// import { createRoutes } from 'src/routes'
import { createRoutes } from './routes/index'
import useAuth from 'src/hooks/useAuth'
import { SettingsProvider } from 'src/contexts/settings'
import Notification from 'src/components/Notification'

import 'react-toastify/dist/ReactToastify.css'
import 'react-confirm-alert/src/react-confirm-alert.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'
import 'animate.css'

const NotFound = lazy(() => import('./views/NotFound/NotFound'))
const LoginPageLayout = lazy(() =>
  import('src/components/Layouts/LoginPageLayout'),
)
const AppBar = lazy(() => import('./components/Appbar'))
const UserLogin = lazy(() => import('./components/Login/Login'))
const SignUp = lazy(() => import('./components/SignUp/SignUp'))
const Footer = lazy(() => import('./components/Footer/Footer'))
const ResetPassword = lazy(() =>
  import('./components/ResetPassword/ResetPassword'),
)
const CheckInterviewStatus = lazy(() =>
  import('./components/CheckInterviewStatus/CheckInterviewStatus'),
)

function App() {
  const [online, setOnline] = useState(true)
  const { t } = useTranslation()
  const { userProfile } = useAuth()
  document.title = t('common.appTitle')

  const routes = useMemo(() => createRoutes(userProfile), [userProfile])

  const handleInternetChange = (e) => {
    if (e.type === 'online') setOnline(true)
    else setOnline(false)
  }

  useEffect(() => {
    window.addEventListener('online', handleInternetChange, false)
    window.addEventListener('offline', handleInternetChange, false)
    return () => {
      window.removeEventListener('online', handleInternetChange)
      window.removeEventListener('offline', handleInternetChange)
    }
  }, [])

  return (
    <div className="App">
      <div className="content">
        <ErrorBoundary fallback={<div />}>
          <SettingsProvider>
            <AppBar />
            {!online ? (
              <Notification
                color="warning"
                message={t('common.noInternet')}
                className={`${
                  userProfile?.roles?.length ? 'internet_notification' : ''
                } m-auto`}
              />
            ) : null}
            <Switch>
              <Route path="/" exact>
                <LoginPageLayout>
                  <UserLogin />
                </LoginPageLayout>
              </Route>
              <Route path="/sign-up" exact>
                <LoginPageLayout>
                  <SignUp />
                </LoginPageLayout>
              </Route>
              <Route path="/reset-password" exact>
                <LoginPageLayout>
                  <ResetPassword />
                </LoginPageLayout>
              </Route>
              <Route path="/cstatus" exact>
                <CheckInterviewStatus />
              </Route>
              {routes.map((route) => (
                <Route
                  key={route.name}
                  path={route.path}
                  component={() => (
                    <>
                      {/* <AppBar /> */}
                      <route.handler />
                    </>
                  )}
                  exact
                />
              ))}
              <Route
                component={() => (
                  <>
                    {/* <AppBar /> */}
                    <NotFound />
                  </>
                )}
              />
            </Switch>
          </SettingsProvider>
        </ErrorBoundary>
      </div>
      <Footer />
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
    </div>
  )
}

export default App
