import { deleteApi, getApi, postApi, putApi } from 'src/services/request'

export const postUser = (payload) => postApi('/users', payload)

export const putUser = (payload) => putApi(`/users/${payload?.id}`, payload)

export const signInAsUser = (payload) =>
  postApi('/candidate/sign-in-as', payload)

export const getUserList = async (params) => {
  try {
    const { pagination = { limit: 10 }, order = {}, where = {} } = params
    const ordersBy = Object.keys(order).map((it) => `${it} ${order[it]}`)
    const whereIs = {}

    Object.keys(where).map((it) => {
      whereIs[it] = {
        like: `%${where[it]}%`,
      }
    })

    const paginationsIs = {
      limit: 10,
      skip: 0,
      ...pagination,
    }

    const objectQuery = {
      ...paginationsIs,
      ...(ordersBy.length > 0 && { order: ordersBy }),
      ...(Object.keys(whereIs).length > 0 && {
        where: whereIs,
      }),
    }

    const jsonQuery = escape(JSON.stringify(objectQuery))
    const whereQuery = escape(JSON.stringify(whereIs))
    const [count, items] = await Promise.allSettled([
      getApi(`/users/count?where=${whereQuery}`),
      getApi(`/users?filter=${jsonQuery}`),
    ])

    return {
      data: items?.value.data,
      meta: count?.value?.data,
    }
  } catch (error) {
    throw error
  }
}

export const getSingleUser = async (userId) => {
  try {
    const { data } = await getApi(`/users/${userId}`)
    return data
  } catch (error) {
    throw error
  }
}

export const deleteSingleUser = async (userId) => {
  try {
    await deleteApi(`/users/${userId}`)
  } catch (error) {
    throw error
  }
}
