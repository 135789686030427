import { createAsyncThunk } from '@reduxjs/toolkit'

import {
  getAdministrationCandidateList,
  getAdministrationCandidateFullList,
} from '../../services/administrationsSvc'

import { GET_ADMINISTRATION_CANDIDATE_LIST } from './constants'

export const getAdministrationCandidateListAct = createAsyncThunk(
  GET_ADMINISTRATION_CANDIDATE_LIST,
  async (params) => {
    try {
      if (
        !params?.where?.administrationId ||
        params?.where?.administrationId?.inq
      ) {
        const resp = getAdministrationCandidateFullList(params)
        return resp
      }
      const resp = await getAdministrationCandidateList(
        params?.where?.administrationId,
        params,
      )
      return resp
    } catch (error) {
      return Promise.reject(error?.data?.error)
    }
  },
)
