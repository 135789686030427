import React, { Suspense } from 'react'
import * as Sentry from '@sentry/react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import store from './redux/store'

// DONOT REMOVE
import { i18 } from './i18n'
import OverlayLoader from './components/UIKit/Loader/OverlayLoader'

Sentry.init({
  dsn: 'https://c62adb8ae1d64c5b998a98a76670a0de@monitoring.tawazy.dev/2',
  autoSessionTracking: false,
  environment: 'development',
  attachStacktrace: true,
  tracesSampleRate: 1.0,
  release: '2.1.0',
})

ReactDOM.render(
  <BrowserRouter>
    <React.StrictMode>
      <Provider store={store}>
        <Suspense fallback={<OverlayLoader show={true} />}>
          <App />
        </Suspense>
      </Provider>
    </React.StrictMode>
  </BrowserRouter>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
